<template>
  <div>
    <Breadcrumbs
      :items="breadcrumbs"
      page_title="banner"
      :item_no="item_no"
      :item_btn_add="true"
      :btn_text="'addnew'"
      @ShowAction="ShowAction"
    />
    <v-container fluid>
      <v-card>
        <v-row justify="space-between" class="ma-1 py-2">
          <v-col cols="12" xs="12" sm="6" md="4" xl="3">
            <v-text-field
              outlined
              dense
              hide-details
              style="border-radius: 8px; border-color: #c0c0c0"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="8"
            xl="9"
            style="text-align: right !important"
          >
            <v-btn
              class="text-capitalize"
              style="
                color: #424242;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              @click="showFilterTools = !showFilterTools"
            >
              <v-icon class="mr-2" :small="true">mdi-tune-variant</v-icon>
              {{ $t("filter") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showFilterTools" class="ma-1 px-2">
          <v-col cols="12" xs="12" sm="6" md="4" xl="3">
            <label
              for="createdatem"
              style="
                font-size: 16px;
                color: #424242;
                font-weight: bold;
                margin-top: 5px;
              "
              >{{ $t("createdateM") }}</label
            >
            <v-menu
              v-model="frommenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromdate"
                  append-icon="mdi-calendar"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromdate"
                @input="startDateDis"
                :allowed-dates="allowedDates"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="4" xl="3">
            <label
              for="enddate"
              style="
                font-size: 16px;
                color: #47484b;
                font-weight: bold;
                margin-top: 5px;
              "
              >{{ $t("enddate") }}</label
            >
            <v-menu
              v-model="tomenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="todate"
                  append-icon="mdi-calendar"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="todate"
                @input="tomenu = false"
                :allowed-dates="allowedDatesEnd"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="4"
            xl="6"
            :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-top: 2.2rem'"
          >
            <v-btn
              text
              class="text-capitalize"
              height="40"
              min-width="100"
              style="
                border: 1px solid #c0c0c0;
                border-radius: 8px;
                color: #424242;
                font-size: 16px;
              "
              @click="clearAll()"
              >{{ $t("clearall") }}</v-btn
            >
            <v-btn
              min-width="100"
              height="40"
              class="text-capitalize btn_hover_effect ml-4"
              style="
                color: #ffff;
                border: 1px solid #a6cc39;
                border-radius: 8px;
                font-size: 16px;
              "
              color="#A6CC39"
              @click="OnChangeSDate()"
              >{{ $t("search") }}</v-btn
            >
          </v-col>
          <v-progress-circular
            v-if="loading"
            indeterminate
            class="loading-circle"
            color="green"
          ></v-progress-circular>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" class="px-3">
            <!-- Loading Bar -->
            <v-progress-linear
              v-if="tableloading"
              indeterminate
              color="primary"
              height="4"
              rounded
              class="mb-2"
            ></v-progress-linear>
            <p
              v-if="tableloading"
              class="mb-0 text-center"
              style="color: #a6cc39"
            >
              {{ $t("loadingpleasewait") }}
            </p>

            <v-row
              v-for="item in paginatedGroups"
              :key="item.id"
              class="align-center pt-2"
              style="border-bottom: 1px solid #e0e0e0"
            >
              <!-- Image Column -->
              <v-col auto style="min-width: 190px">
                <v-img
                  :src="item.orgImage"
                  style="width: 100%; height: auto; border-radius: 16px"
                ></v-img>
              </v-col>

              <!-- Title and Description Column -->
              <v-col auto class="flex-column d-flex" style="min-width: 270px">
                <div style="color: #424242">
                  <div style="font-size: 18px; font-weight: 500">
                    {{ item.title }}
                  </div>
                  <div class="mt-2">{{ item.description }}</div>
                </div>
              </v-col>

              <!-- Created Date Column -->
              <v-col auto>
                <div style="color: #424242">
                  <div style="font-size: 18px; font-weight: 500">
                    {{ $t("createddate") }}
                  </div>
                  <div class="mt-2">{{ item.createdDate }}</div>
                </div>
              </v-col>

              <!-- Start Date and Time Column -->
              <v-col auto>
                <div style="color: #424242">
                  <div style="font-size: 18px; font-weight: 500">
                    {{ $t("startdate") }} - {{ $t("timesm") }}
                  </div>
                  <div class="mt-2">{{ item.startTime }}</div>
                </div>
              </v-col>

              <!-- End Date and Time Column -->
              <v-col auto>
                <div style="color: #424242">
                  <div style="font-size: 18px; font-weight: 500">
                    {{ $t("enddate") }} - {{ $t("timesm") }}
                  </div>
                  <div class="mt-2">{{ item.endTime }}</div>
                </div>
              </v-col>

              <!-- Status & Actions Column -->
              <v-col auto class="d-flex flex-row justify-space-between px-1">
                <div style="color: #424242; text-align: center">
                  <div style="font-size: 18px; font-weight: 500">
                    {{ $t("status") }}
                  </div>
                  <div
                    class="mt-2"
                    :style="{ color: getStatusColor(item.status) }"
                  >
                    {{ getStatusText(item.status) }}
                  </div>
                </div>
                <div class="d-flex text-left align-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="editBanner(item)">
                        <v-icon left>mdi-square-edit-outline</v-icon>
                        {{ $t("edit") }}
                      </v-list-item>
                      <v-list-item @click="checkBannerDelete(item)">
                        <v-icon left color="#FF6060">mdi-delete-outline</v-icon>
                        {{ $t("delete") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="justify-space-between mt-5 pb-1">
          <v-col cols="12" xs="12" sm="9">
            <div class="d-flex text-left">
              <v-pagination
                circle
                v-model="page"
                class="pagination"
                :length="totalPages"
              ></v-pagination>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="3" class="d-flex justify-end pr-3">
            <p class="mt-3 pr-4 mb-0">{{ $t("Show") }}</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  width="70"
                  v-bind="attrs"
                  v-on="on"
                  style="border: 1px solid #c0c0c0; opacity: 1"
                  class="text-capitalize mt-1"
                  outlined
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in paginationCount"
                  :key="index"
                  @click="itemsPerPage = item.title"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <!-- NewBanner -->
    <v-dialog
      width="1200"
      v-model="newbannerdialog"
      persistent
      :scrollable="true"
    >
      <v-card>
        <v-card-title style="font-size: 30px; height: 80px">
          <v-icon
            class="mr-1"
            style="padding-bottom: 3px; color: #363636; font-size: 30px"
            >mdi-plus-box-outline</v-icon
          >
          {{ $t("addbanner") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="cancelBanner()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" lg="12">
                <p style="color: #424242; font-size: 16px">
                  {{ $t("createdateM") }}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {{ this.createdate }} {{ $t("By") }} {{ this.fullName }}
                </p>
              </v-col>
              <v-col cols="12" lg="7">
                <v-row>
                  <v-col cols="12">
                    <label for="bannertitle" class="font-style">
                      {{ $t("bannertitle") }}
                      <span style="color: red">*</span>
                    </label>
                    <v-text-field
                      v-model="bannerTitle"
                      :rules="titleRules"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="12">
                    <label for="description" class="font-style">{{
                      $t("description")
                    }}</label>
                    <v-text-field
                      v-model="description"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3" class="datetime">
                    <label for="stardatetime" class="font-style">{{
                      $t("startdatetime")
                    }}</label>
                    <v-menu
                      v-model="startdatemenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startdate"
                          append-icon="mdi-calendar"
                          outlined
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedStartDates"
                        color="secondary"
                        v-model="startdate"
                        @input="startdateDiss()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col for="time" cols="12" lg="3" class="datetime">
                    <v-menu
                      ref="timeMenu"
                      v-model="startmenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="starttime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="starttime"
                          :rules="startTimeRules"
                          readonly
                          v-bind="attrs"
                          class="mt-6"
                          v-on="on"
                          outlined
                          dense
                          hide-details="auto"
                          id="time"
                          append-icon="mdi-clock-outline"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        ampm-in-title
                        v-if="startmenu"
                        v-model="starttime"
                        full-width
                        @click:minute="$refs.timeMenu.save(starttime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" lg="3" class="datetime">
                    <div class="d-inline">
                      <label for="enddatetime" class="font-style">{{
                        $t("enddatetime")
                      }}</label>
                      <v-menu
                        v-model="enddatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="enddate"
                            append-icon="mdi-calendar"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :allowed-dates="allowedEndDates"
                          color="secondary"
                          v-model="enddate"
                          @input="enddatemenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="3" for="timeE">
                    <v-menu
                      ref="timemenu"
                      v-model="endmenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="endtime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endtime"
                          :rules="endTimeRules"
                          readonly
                          v-bind="attrs"
                          class="mt-6"
                          v-on="on"
                          outlined
                          dense
                          hide-details="auto"
                          id="timeE"
                          append-icon="mdi-clock-outline"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        ampm-in-title
                        v-if="endmenu"
                        v-model="endtime"
                        full-width
                        @click:minute="$refs.timemenu.save(endtime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="5">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="7">
                    <p
                      class="text-left font-style"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>

                    <div class="drop">
                      <v-card
                        width="290"
                        height="200"
                        style="
                          border: 2px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.file.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropImg($event)"
                      >
                        <v-img
                          v-if="file"
                          :src="file"
                          @click="$refs.file.click()"
                          @mouseover="
                            file
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            file
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="240"
                          height="167"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="previewimg = true"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="error"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="file = null"
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>
                        <v-img
                          v-if="!file"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.file.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="file"
                          accept="image/*"
                          @change="uploadImg($event, 1)"
                        />
                        <p
                          v-show="!file"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!file"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.file.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p
                        class="mt-3 pr-3"
                        style="color: #777777; font-size: 12px"
                      >
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-progress-circular
              v-if="addloading"
              indeterminate
              class="loading-circle"
              color="green"
            ></v-progress-circular>

            <v-row rows="6" xs="12" sm="12" md="6" lg="6">
              <v-col class="d-inline" cols="5" xs="12" sm="12" md="7" lg="3">
                <label for="actionbanner" class="font-style">{{
                  $t("actionbanner")
                }}</label>
                <template>
                  <div>
                    <v-select
                      v-model="selectedItem"
                      :items="itemsselect"
                      class="custom-v-select"
                      item-text="txt"
                      :item-value="(item) => item.val"
                      outlined
                    ></v-select>
                  </div>
                </template>
              </v-col>
              <v-col
                class="d-inline"
                cols="7"
                xs="12"
                sm="12"
                md="7"
                lg="7"
                style="margin-top: 22px"
              >
                <v-row v-if="selectedItem === 'Link URL'">
                  <v-col
                    class="d-inline"
                    cols="7"
                    xs="12"
                    sm="12"
                    md="7"
                    lg="3"
                    style="margin-top: 10px"
                  >
                    <label for="linkurl" class="font-styles">{{
                      $t("linkurl")
                    }}</label>
                  </v-col>
                  <v-col
                    class="d-inline"
                    cols="7"
                    xs="12"
                    sm="12"
                    md="7"
                    lg="9"
                  >
                    <v-text-field
                      v-model="linkUrl"
                      outlined
                      dense
                      solo-filled
                      hide-details="auto"
                      style="padding-right: 9px"
                      @input="checkLinkType"
                    ></v-text-field>
                    <span v-if="notLink" style="color: red; font-size: 13px">{{
                      $t("invalidUrlLink")
                    }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row></v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-5 mt-3">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            text
            outlined
            width="100"
            @click="cancelBanner()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style white--text"
            color="secondary"
            width="100"
            @click="saveBanner()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- EditBanner   -->
    <v-dialog
      width="1200"
      v-model="editbannerdialog"
      persistent
      :scrollable="true"
    >
      <v-card>
        <v-card-title style="font-size: 30px; height: 70px">
          <v-icon
            class="mr-1"
            style="padding-bottom: 3px; color: #363636; font-size: 30px"
            >mdi-square-edit-outline</v-icon
          >
          {{ $t("editbanner") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="cancelBanner(), (editbannerdialog = false)"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12">
                <p style="font-size: 16px; color: #47484b">
                  {{ $t("createdateM") }}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {{ this.createDate }}&nbsp;&nbsp; {{ $t("By") }}
                  {{ this.createBy }}
                </p>
                <p
                  style="font-size: 16px; color: #47484b"
                  v-if="this.updateBy != null"
                >
                  {{ $t("Last Update") }}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {{ this.updateDate }}&nbsp;&nbsp; {{ $t("By") }}
                  {{ this.updateBy }}
                </p>
              </v-col>
              <v-col cols="12" lg="7">
                <v-row>
                  <v-col cols="12">
                    <label for="bannertitle" class="font-style">
                      {{ $t("bannertitle") }}
                      <span style="color: red">*</span>
                    </label>
                    <v-text-field
                      v-model="bannerTitle"
                      :rules="titleRules"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="12">
                    <label for="description" class="font-style">{{
                      $t("description")
                    }}</label>
                    <v-text-field
                      v-model="description"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3" class="datetime">
                    <label for="startdatetime" class="font-style">{{
                      $t("startdatetime")
                    }}</label>
                    <v-menu
                      v-model="editstartdatemenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editstartdate"
                          append-icon="mdi-calendar"
                          outlined
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editstartdate"
                        :allowed-dates="allowedStartDates"
                        @input="editstartdateDiss()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col for="edittime" cols="12" lg="3" class="datetime">
                    <v-menu
                      ref="timeMenuedit"
                      v-model="editstartmenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="starttime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editstarttime"
                          :rules="startTimeRules"
                          readonly
                          v-bind="attrs"
                          class="mt-6"
                          v-on="on"
                          outlined
                          dense
                          hide-details="auto"
                          id="edittime"
                          append-icon="mdi-clock-outline"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        ampm-in-title
                        v-if="editstartmenu"
                        v-model="editstarttime"
                        full-width
                        @click:minute="$refs.timeMenuedit.save(editstarttime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" lg="3" class="datetime">
                    <div class="d-inline">
                      <label for="enddatetime" class="font-style">{{
                        $t("enddatetime")
                      }}</label>
                      <v-menu
                        v-model="editenddatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editenddate"
                            append-icon="mdi-calendar"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editenddate"
                          :allowed-dates="allowedEndDatess"
                          @input="editenddatemenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="3" class="datetime" for="edittimeE">
                    <v-menu
                      ref="edittimemenu"
                      v-model="editendmenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="editendtime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editendtime"
                          :rules="endTimeRules"
                          readonly
                          v-bind="attrs"
                          class="mt-6"
                          v-on="on"
                          outlined
                          dense
                          hide-details="auto"
                          id="edittimeE"
                          append-icon="mdi-clock-outline"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        ampm-in-title
                        v-if="editendmenu"
                        v-model="editendtime"
                        full-width
                        @click:minute="$refs.edittimemenu.save(editendtime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="5">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="7">
                    <p
                      class="text-left font-style"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>

                    <div class="drop">
                      <v-card
                        width="290"
                        height="200"
                        style="
                          border: 2px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.file.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropImg($event)"
                      >
                        <v-img
                          v-if="file"
                          :src="file"
                          @click="$refs.file.click()"
                          @mouseover="
                            file
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            file
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="240"
                          height="167"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="previewimg = true"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="error"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="file = null"
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>
                        <v-img
                          v-if="!file"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.file.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="file"
                          accept="image/*"
                          @change="uploadImg($event, 1)"
                        />
                        <p
                          v-show="!file"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!file"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.file.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p
                        class="mt-3 pr-3"
                        style="color: #777777; font-size: 12px"
                      >
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row rows="6" xs="12" sm="12" md="6" lg="6">
              <v-col class="d-inline" cols="5" xs="12" sm="12" md="7" lg="3">
                <label for="actionbanner" class="font-style">{{
                  $t("actionbanner")
                }}</label>
                <template>
                  <div>
                    <v-select
                      v-model="selectedItem"
                      :items="itemsselect"
                      class="custom-v-select"
                      item-text="txt"
                      :item-value="(item) => item.val"
                      outlined
                    ></v-select>
                  </div>
                </template>
              </v-col>
              <v-col
                class="d-inline"
                cols="7"
                xs="12"
                sm="12"
                md="7"
                lg="7"
                style="margin-top: 22px"
              >
                <v-row v-if="selectedItem === 'Link URL'">
                  <v-col
                    class="d-inline"
                    cols="7"
                    xs="12"
                    sm="12"
                    md="7"
                    lg="3"
                    height="40px"
                    style="margin-top: 10px"
                  >
                    <label for="linkurl" class="font-styles">{{
                      $t("linkurl")
                    }}</label>
                  </v-col>
                  <v-col
                    class="d-inline"
                    cols="7"
                    xs="12"
                    sm="12"
                    md="7"
                    lg="9"
                  >
                    <v-text-field
                      v-model="linkUrl"
                      outlined
                      dense
                      hide-details="auto"
                      style="padding-right: 9px"
                      @input="checkLinkType"
                    ></v-text-field>
                    <span v-if="notLink" style="color: red; font-size: 13px">{{
                      $t("invalidUrlLink")
                    }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-5 mt-3">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            text
            outlined
            width="100"
            @click="cancelBanner(), (editbannerdialog = false)"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style white--text"
            color="secondary"
            width="100"
            @click="updateBanner()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius: 8px; margin: auto"
          :src="file"
        ></v-img>
      </v-card>
    </v-dialog>
    <!-- cropimage -->
    <v-dialog
      v-model="dialogCrop"
      width="1000px"
      height="800px"
      overflow="hidden"
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 30px"
          >{{ $t("crpimg") }}</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            text
            @click="CancelCrop()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style"
            color="primary"
            @click="CropImage()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete -->
    <v-dialog v-model="deletebannerdialog" width="500">
      <v-card>
        <v-card-title style="font-size: 30px; height: 70px">
          <p>{{ $t("deleteconfirm") }}</p>
        </v-card-title>

        <v-card-text>
          <p
            class="mt-5"
            style="font-size: 20px; color: #4d4f5c; text-align: center"
          >
            {{ $t("suredelete") }}
            <br />
            {{ editData.title }}" ?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            style="font-size: 20px"
            width="100"
            text
            outlined
            @click="deletebannerdialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize"
            width="100"
            style="font-size: 20px"
            color="error"
            @click="deleteData()"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Success -->
    <v-dialog v-model="successDialog" width="400">
      <v-card>
        <v-card-title>
          <p
            class="mt-3"
            style="font-size: 20px; color: #4d4f5c; text-align: center"
          >
            <v-icon class="mr-3" style="color: #a6cc39"
              >mdi-checkbox-marked-circle</v-icon
            >
            {{ $t("deletebanner") }}
          </p>
        </v-card-title>
      </v-card>
    </v-dialog>
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
    <SuccessDialog
      :show="addsuccessDialog"
      :title="$t('success')"
      :text="$t('addbannersuccessful')"
      @close="(addsuccessDialog = false), getAllBannerData()"
    />
    <SuccessDialog
      :show="editsuccessDialog"
      title="Success"
      :text="$t('updatebannersuccess')"
      @close="(editsuccessDialog = false), getAllBannerData()"
    />
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import * as moment from "moment/moment";

export default {
  components: {
    VueCropper,
  },
  data: () => ({
    selectedItem: "-",

    // itemsselect: [
    //   { val: "-", txt: "-" },
    //   { val: "Course Menu", txt: "Course Menu" },
    //   { val: "Event Menu", txt: "Event Menu" },
    //   { val: "Link URL", txt: "Link URL" },
    // ],
    selectedRow: null,
    createdate: moment(new Date().toISOString().substr(0, 10))
      .local()
      .format("DD MMM YYYY"),
    fullName: "",
    previewimg: false,
    showEditImgTool: false,
    loading: false,
    addloading: false,
    valid: true,
    fileError: false,
    permissionDialog: false,
    permissionMessage: "",
    addsuccessDialog: false,
    editsuccessDialog: false,
    endtime: null,
    time: null,
    starttime: null,
    endmenu: false,
    startmenu: false,
    editendtime: null,
    editstarttime: null,
    editendmenu: false,
    editstartmenu: false,
    breadcrumbs: [
      {
        //image: "house.png"
        sidebar_tilte: "home",
      },
      {
        text: "banner", //assignmentcourse
      },
    ],
    item_no: 0,
    showFilterTools: false,
    newbannerdialog: false,
    editbannerdialog: false,
    deletebannerdialog: false,
    fromdate: new Date().toISOString().substr(0, 10),
    todate: new Date().toISOString().substr(0, 10),
    frommenu: false,
    tomenu: false,
    startdate: new Date().toISOString().substr(0, 10),
    enddate: new Date().toISOString().substr(0, 10),
    startdatemenu: false,
    enddatemenu: false,
    editstartdate: new Date().toISOString().substr(0, 10),
    editenddate: new Date().toISOString().substr(0, 10),
    editstartdatemenu: false,
    editenddatemenu: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    items: [],
    dialog: false,
    successDialog: false,
    message: "",
    search: null,
    file: null,
    fileName: "",
    fileUrl: "",
    dialogCrop: false,
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: false,
      autoCrop: true,
      autoCropWidth: 800,
      autoCropHeight: 450,
      centerBox: false,
      high: true,
    },
    filteredItems: [],
    editData: {},
    linkUrl: "",
    buttonName: "",
    bannerTitle: "",
    description: "",
    bannerID: 0,
    tableloading: true,
    dpSDate: "",
    dpEDate: "",
    createBy: "",
    updateBy: "",
    createDate: "",
    updateDate: "",
    notLink: false,
  }),
  mounted() {
    let year = new Date().getFullYear();
    let month =
      new Date().getMonth() + 1 <= 9
        ? "0" + parseInt(new Date().getMonth() + 1)
        : new Date().getMonth() + 1;
    let day =
      new Date().getDate() <= 9
        ? "0" + parseInt(new Date().getDate())
        : new Date().getDate();
    this.startDateDis(`${year}-${month}-${day}`);
    this.getAllBannerData();

    const auth = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    if (auth) {
      this.fullName = auth.fullName;
    }
  },
  watch: {
    selectedItem(newSelectedItem) {
      // Reset linkUrl when selectedItem changes
      if (newSelectedItem !== "Link URL") {
        this.linkUrl = "";
        this.notLink = false;
      }
    },
    file: function () {
      this.fileError = false;
    },
    //search: "filterItems",
  },
  computed: {
    paginatedGroups() {
      let self = this;
      let startIndex = (self.page - 1) * self.itemsPerPage;
      let endIndex = startIndex + self.itemsPerPage;
      self.filteredItems = self.items;
      if (self.search != null && self.search != "") {
        self.filteredItems = self.items.filter(
          (x) =>
            x.title.toLowerCase().includes(self.search.toLowerCase()) ||
            x.description.toLowerCase().includes(self.search.toLowerCase())
        );
      }
      return self.filteredItems.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
    isLink() {
      const urlRegex = /^(https?:\/\/(www\.)|(www\.))|(ftp|http(s)?:\/\/).+$/;
      // Return true if the input matches the URL pattern, false otherwise
      // return this.linkUrl.length != 0
      //   ? urlRegex.test(this.linkUrl)
      //   : true;
      let result;
      if (this.linkUrl != null) {
        if (this.linkUrl.length != 0) {
          result = urlRegex.test(this.linkUrl);
        } else {
          result = true;
        }
      } else {
        result = true;
      }
      return result;
      // return urlRegex.test(this.linkUrl);
      // Regular expression to check if the input is a valid URL
      // const urlRegex = /^(https?:\/\/(www\.)|(www\.))|(ftp|http(s)?:\/\/).+$/;
    },
    titleRules() {
      return [(v) => !!v || "Banner Title is required"];
    },
    startTimeRules() {
      return [(v) => !!v || "Specify start time"];
    },
    endTimeRules() {
      return [(v) => !!v || "Specify end time"];
    },
    itemsselect() {
      return [
        { val: "-", txt: "-" },
        { val: "Course Menu", txt: this.$t("coursemenu") },
        { val: "Event Menu", txt: this.$t("eventmenu") },
        { val: "Link URL", txt: this.$t("linkurl") },
      ];
    },
  },
  methods: {
    // Filter the items based on the search query
    //       t    filterItems() {
    // his.filteredItems = this.items.filter((item) =>
    //         item.title.toLowerCase().includes(this.search.toLowerCase())
    //       );
    //     },
    selectRow(rowItem) {
      // Clear previously selected row if any
      if (this.selectedRow === rowItem) {
        this.selectedRow = null;
      } else {
        this.selectedRow = rowItem;
      }
    },
    allowedEndDates(val) {
      return val >= this.startdate;
    },
    allowedEndDatess(val) {
      return val >= this.editstartdate;
    },
    allowedStartDates: (val) => val >= new Date().toISOString().substr(0, 10),
    startdateDiss() {
      let that = this;
      that.startdatemenu = false;
      if (that.startdate > that.enddate) {
        that.enddate = that.startdate;
      }
    },
    getStatusText(status) {
      return status === "Active" ? this.$t("active") : this.$t("inactive");
    },
    getStatusColor(status) {
      return status === "Active" ? "green" : "red";
    },
    checkLinkType() {
      if (this.isLink || this.linkUrl === "") {
        this.notLink = false;
      } else {
        this.notLink = true;
      }
    },
    clearAll() {
      this.search = null;
      this.dpSDate = "";
      this.dpEDate = "";
      this.getAllBannerData();
      this.todate = new Date().toISOString().substr(0, 10);
      this.fromdate = new Date().toISOString().substr(0, 10);
    },
    ShowAction(v) {
      if (v == "Add") {
        this.addBanner();
      }
    },
    OnChangeSDate() {
      this.dpSDate = this.fromdate + " 00:00";
      this.dpEDate = this.todate + " 23:59";
      this.getAllBannerData();
    },
    async checkBannerDelete(item) {
      let self = this;

      let checkPermission = self.check_Permissions("banner");
      if (checkPermission === false) {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to delete Banner.";
        return false;
      }

      self.deletebannerdialog = true;
      self.editData = item;
    },
    async deleteData() {
      let self = this;
      await self.$axios
        .post(`${self.web_url}Banner/DeleteBanner?ID=` + self.editData.id)
        .then(function (res) {
          self.message = res.data.message;
          self.successDialog = true;
          self.deletebannerdialog = false;
          self.getAllBannerData();
        })
        .catch(function (err) {
          alert(err);
        });
    },
    editBanner(item) {
      let checkPermission = this.check_Permissions("banner");
      if (checkPermission === false) {
        this.permissionDialog = true;
        this.permissionMessage = "You don't have permission to edit Banner.";
        return false;
      }

      let newstarttime = item.startTime.split(" ");
      let startTime = newstarttime[2];
      let resStartTime = null;
      if (newstarttime[3] == "PM") {
        let separatetime = parseInt(startTime.split(":")[0]);
        let addtime = separatetime == 12 ? separatetime : separatetime + 12;
        resStartTime = addtime + ":" + startTime.split(":")[1];
      } else {
        if (startTime.split(":")[0] <= 9) {
          resStartTime =
            0 + startTime.split(":")[0] + ":" + startTime.split(":")[1];
        } else {
          resStartTime = startTime;
        }
      }

      let newendtime = item.endTime.split(" ");
      let endTime = newendtime[2];
      let resEndTime = null;
      if (newendtime[3] == "PM") {
        let separateendtime = parseInt(endTime.split(":")[0]);
        let endaddtime =
          separateendtime == 12 ? separateendtime : separateendtime + 12;
        resEndTime = endaddtime + ":" + endTime.split(":")[1];
      } else if (endTime.split(":")[0] <= 9) {
        resEndTime = 0 + endTime.split(":")[0] + ":" + endTime.split(":")[1];
      } else {
        resEndTime = endTime;
      }
      this.editbannerdialog = true;
      this.bannerID = item.id;
      this.bannerTitle = item.title;
      this.editstartdate = item.startDate.substring(0, 10);
      this.editstarttime = resStartTime;
      this.editendtime = resEndTime;
      this.editenddate = item.endDate.substring(0, 10);
      this.file = item.orgImage;
      this.thumbnail = item.thumbnail;
      this.description = item.description;
      this.buttonName = item.buttonName;
      this.linkUrl = item.linkURL;
      this.createDate = moment(item.createDate).local().format("DD MMM YYYY");
      this.createBy = item.createBy;
      this.updateBy = item.updateBy;
      this.updateDate = moment(item.updateDate).local().format("DD MMM YYYY");
      this.selectedItem = item.actionPage;
    },
    editformatDate() {
      const [year, month, day] = this.editstartdate.split("-");
      return `${day}/${month}/${year}`;
    },
    editformatendDate() {
      const [year, month, day] = this.editenddate.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDate() {
      const [year, month, day] = this.startdate.split("-");
      return `${day}/${month}/${year}`;
    },
    newformatDate(newdate) {
      const newdate1 = newdate.split("T");
      const newtime = newdate1[1].split(":");
      const [year, month, day] = newdate1[0].split("-");
      const returndate =
        `${day}/${month}/${year}` + " " + newtime[0] + ":" + newtime[1] + ":00";
      return returndate;
    },
    formatEndDate() {
      const [year, month, day] = this.enddate.split("-");
      return `${day}/${month}/${year}`;
    },
    async getAllBannerData() {
      let self = this;
      this.tableloading = true;
      await self.$axios
        .get(
          `${self.web_url}Banner/GetAllBanner?CompanyID=` +
            localStorage.getItem("companyID") +
            "&startDate=" +
            self.dpSDate +
            "&endDate=" +
            self.dpEDate
        )
        .then(function (res) {
          self.items = res.data.data.map((v, i) => ({
            ...v,
            no: i + 1,
            startTime: moment(v.startTime).local().format("DD/MM/YYYY  LT"),
            endTime: moment(v.endTime).local().format("DD/MM/YYYY  LT"),
            createdDate: moment(v.createDate).local().format("DD/MM/YYYY"),
          }));
        })
        .catch(function (err) {
          alert(err);
        });

      self.item_no = self.items.length;
      this.tableloading = false;
    },
    async saveBanner() {
      let self = this;
      if ((this.$refs.form.validate() && this.isLink) || this.linkUrl === "") {
        this.addloading = true;
        if (this.file) {
          const base64Res = await this.$axios.post(
            `${this.web_url}File/UploadFileStringBase`,
            {
              base64: this.file,
              fileName: this.fileName,
            }
          );
          const startdata = new Date(
            this.startdate + "T" + this.starttime + ":00"
          );
          const startisodata = startdata.toISOString();

          const enddata = new Date(this.enddate + "T" + this.endtime + ":00");
          const endisodata = enddata.toISOString();
          const userdata = JSON.parse(
            localStorage.getItem("vivek_authenticated_user_data")
          );
          const memberid = userdata.memberId;
          const data = {
            id: 0,
            title: this.bannerTitle,
            description: this.description,
            startDate: this.startdate,
            startTime: this.newformatDate(startisodata),
            endDate: this.enddate,
            endTime: this.newformatDate(endisodata),
            buttonName: this.buttonName,
            pic: base64Res.data,
            imgBase64: this.file,
            linkURL: this.linkUrl,
            userID: parseInt(localStorage.getItem("UserID")),
            companyID: localStorage.getItem("companyID"),
            CreateBy: memberid,
            actionPage: this.selectedItem,
            // updateBy: localStorage.getItem("userName"),
          };
          await this.$axios
            .post(`${this.web_url}Banner/AddOrUpdateBanner`, data)
            .then(function (res) {
              if (res.data.status == 0) {
                self.addsuccessDialog = true;
                self.cancelBanner();
                self.getAllBannerData();
                self.newbannerdialog = false;
              }
            })
            .catch(function (err) {
              alert(err);
            });
        } else {
          this.fileError = true;
          alert("Please attach a picture!");
        }
      } else {
        this.fileError = true;
      }

      this.addloading = false;
    },
    async updateBanner() {
      let self = this;
      let bgPic = "";
      if (
        (this.$refs.form.validate() && this.file && this.isLink) ||
        this.linkUrl === ""
      ) {
        this.loading = true;
        if (this.fileName != null && this.fileName != "") {
          const base64Res = await this.$axios.post(
            `${this.web_url}File/UploadFileStringBase`,
            {
              base64: this.file,
              fileName: this.fileName,
            }
          );
          bgPic = base64Res.data;
        } else {
          bgPic = this.file;
        }
        const startdata = new Date(
          this.editstartdate + "T" + this.editstarttime + ":00"
        );
        const startisodata = startdata.toISOString();

        const enddata = new Date(
          this.editenddate + "T" + this.editendtime + ":00"
        );
        const endisodata = enddata.toISOString();
        const userdata = JSON.parse(
          localStorage.getItem("vivek_authenticated_user_data")
        );
        const memberid = userdata.memberId;
        const data = {
          id: this.bannerID,
          title: this.bannerTitle,
          description: this.description,
          startDate: this.editstartdate,
          startTime: this.newformatDate(startisodata),
          endDate: this.editenddate,
          endTime: this.newformatDate(endisodata),
          buttonName: this.buttonName,
          pic: bgPic,
          imgBase64:
            this.fileName != "" && this.fileName != null
              ? this.file
              : this.thumbnail,
          linkURL: this.linkUrl,
          actionPage: this.selectedItem,
          userID: parseInt(localStorage.getItem("UserID")),
          companyID: localStorage.getItem("companyID"),
          updateBy: memberid,
        };
        await this.$axios
          .post(`${this.web_url}Banner/AddOrUpdateBanner`, data)
          .then(function (res) {
            if (res.data.status == 0) {
              self.editsuccessDialog = true;
              self.cancelBanner();
              self.getAllBannerData();
            }
          })
          .catch(function (err) {
            alert(err);
          });
      } else {
        this.fileError = true;
      }
      this.loading = false;
    },
    allowedDates: (val) => new Date(val),
    startDateDis(selected) {
      this.calcRes(selected);
      this.frommenu = false;
      this.allowedDatesEnd.todate = selected;
      if (this.todate < this.allowedDatesEnd.todate) {
        this.todate = this.allowedDatesEnd.todate;
      }
    },
    allowedDatesEnd(val) {
      return val >= this.endDate;
    },
    calcRes(e) {
      this.endDate = e;
    },
    // Edit
    startallowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
    endallowedDates(val) {
      return val >= this.editstartdate;
    },
    editcalcRes(e) {
      this.editenddate = e;
    },
    editstartdateDiss() {
      let that = this;
      that.editstartdatemenu = false;
      if (that.editstartdate > that.editenddate) {
        that.editenddate = that.editstartdate;
      }
    },
    editstartDateDis(selected) {
      this.editcalcRes(selected);
      this.editstartdatemenu = false;
      this.endallowedDates.editstartdate = selected;
      if (this.editstartdate < this.endallowedDates.editstartdate) {
        this.editstartdate = this.endallowedDates.editstartdate;
      }
    },
    //Add
    newallowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
    newstartDateDis(selected) {
      this.newcalcRes(selected);
      this.startdatemenu = false;
      this.newendallowedDates.startdate = selected;
      if (this.startdate < this.newendallowedDates.startdate) {
        this.startdate = this.newendallowedDates.startdate;
      }
    },
    newendallowedDates(val) {
      return val >= this.startdate;
    },
    newcalcRes(e) {
      this.enddate = e;
    },
    addBanner() {
      let self = this;
      let checkPermission = self.check_Permissions("banner");
      if (checkPermission === false) {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to add Banner.";
        return false;
      }
      self.newbannerdialog = true;
    },
    cancelBanner() {
      this.$refs.form.resetValidation();
      this.newbannerdialog = false;
      this.starttime = "";
      this.bannerTitle = "";
      this.startdate = new Date().toISOString().substr(0, 10);
      this.endtime = "";
      this.enddate = new Date().toISOString().substr(0, 10);
      this.file = null;
      this.fileName = null;
      this.fileUrl = "";
      this.description = null;
      this.buttonName = null;
      this.linkUrl = null;
      this.editbannerdialog = false;
      this.selectedItem = "-";
    },
    getFile(e) {
      this.fileUrl = e;
      this.file = e;
      this.showEditImgTool = false;
    },
    uploadImg(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      this.fileName = file.name;
      this.filesize = parseFloat(file.size / 1000000).toFixed(2) + "MB";
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    CancelCrop() {
      this.dialogCrop = false;
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.getFile(data);
        this.dialogCrop = false;
      });
    },
  },
};
</script>
<style scoped>
* ::v-deep .v-select__selection--comma {
  width: 100%;
  padding-left: 40px;
}
* ::v-deep .mdi-menu-down {
  content: "\F035D";
  padding-bottom: 7px;
}
* ::v-deep .custom-v-select .v-input__slot {
  max-height: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.custom-v-select .v-list-item__title {
  font-size: 16px;
  text-align: center;
}
.text-green {
  color: green;
}

.text-red {
  color: red;
}
.status-text:nth-child(odd) {
  color: green; /* Set the text color to green for odd indices (e.g., "active") */
}

.status-text:nth-child(even) {
  color: red; /* Set the text color to red for even indices (e.g., "inactive") */
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
.description-class {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: top;
}
::v-deep .v-dialog {
  border-radius: 16px !important;
}
::v-deep .to-date .v-input__slot {
  width: 155px !important;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
.font-styles {
  font-size: 16px;
  color: #4d4f5c;

  padding-left: 70px;
}
.btnfont-style {
  font-size: 20px;
}
::v-deep .v-input__slot {
  border-radius: 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
}
</style>

<style>
.v-card {
  border-radius: 16px !important;
}
.list-item {
  border-bottom: 1px solid rgba(66, 66, 66, 0.2) !important;
}
.datetime {
  padding-right: 0px !important;
}
.v-pagination__navigation {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #c0c0c0;
}
.v-pagination__item {
  background: transparent !important;
  box-shadow: none !important;
}
.v-list-item-group ::before {
  background: transparent;
}
.v-list-item-group ::after {
  background: transparent;
}
</style>
